<template>
	
	<div class="gg-container">
		
		<div class="search-container" >
			<!--对应第三方图标的button样式-->
			<div class="search-container-fn-input" style="margin-right: 50px">
				<el-button
					size="mini"
					type="primary"
					style="padding: 6px 7px 7px 8px; background-color: #2362FB; border-color: #2362FB"
					@click="handlerBar">
					<i class="iconfont el-icon-s-data" style="font-size: 13px"/>
					<span style="margin-left: 5px; font-size: 12px">柱状图</span>
				</el-button>
			</div>
			
			<div class="search-container-fn-input" style="margin-right: 50px">
				<el-button
					size="mini"
					type="primary"
					style="padding: 6px 7px 7px 8px; background-color: #2362FB; border-color: #2362FB"
					@click="handlerLine">
					<i class="iconfont el-icon-data-line" style="font-size: 13px"/>
					<span style="margin-left: 5px; font-size: 12px">折线图</span>
				</el-button>
			</div>
			
			<div class="search-container-fn-input" style="margin-right: 50px">
				<el-button
					size="mini"
					type="primary"
					style="padding: 6px 7px 7px 8px; background-color: #2362FB; border-color: #2362FB"
					@click="handlerPie">
					<i class="iconfont el-icon-pie-chart" style="font-size: 13px"/>
					<span style="margin-left: 5px; font-size: 12px">饼图</span>
				</el-button>
			</div>
		</div>
		
		
		<div ref="recordReportBar" class="record-report-bar"
				 :style="{height: scrollerHeightBar}"></div>
		
		<div ref="recordReportLine" class="record-report-line"
				 :style="{height: scrollerHeightLine}"></div>
		
		<div ref="recordReportPie" class="record-report-pie"
				 :style="{height: scrollerHeightPie}"></div>
		
		
		<div class="search-container">
			<div class="search-container-fn-input" style="margin-right: 50px">
				<el-button
					size="mini"
					type="primary"
					style="padding: 6px 7px 7px 8px; background-color: #2362FB; border-color: #2362FB"
					@click="handlerHideTable">
					<i class="iconfont el-icon-date" style="font-size: 13px"/>
					<span style="margin-left: 5px; font-size: 12px">显示隐藏table表</span>
				</el-button>
			</div>
		</div>
		<div  v-if="isShowTable">
		<div class="search-container">
			<div class="search-container-fn-input">
				<label>日期：</label>
				<el-date-picker
					v-model="choiceDate"
					type="daterange"
					size="mini"
					value-format="yyyy-MM-dd HH-mm-ss "
					range-separator="至"
					start-placeholder="开始日期"
					end-placeholder="结束日期"
					@change="handleChangeDate">
				</el-date-picker>
			</div>
			<!--对应第三方图标的button样式-->
			<div class="search-container-fn-input" style="margin-left: auto;"
					 v-if="operateList.indexOf('export') > -1"
			>
				<el-button
					size="mini"
					type="primary"
					style="padding: 6px 7px 7px 8px; background-color: #2362FB; border-color: #2362FB"
					@click="handlerExportExcel">
					<i class="iconfont icon-daoru" style="font-size: 13px"/>
					<span style="margin-left: 5px; font-size: 12px">导出</span>
				</el-button>
			</div>
		</div>
		<!----------------表格---------------->
		<el-table
			border
			height="625"
			v-loading="listLoading"
			:header-cell-style="{'text-align':'center'}"
			:data="tableData"
			@selection-change="handleSelectionChange"
			style="width: 100%;z-index:0">
			<el-table-column
				type="index"
				align="center"
				width="50">
			</el-table-column>
			<el-table-column type="selection" width="55" align="center" />
			<el-table-column
				prop="staff_name"
				label="客服"
				align="center"
				width="80">
			</el-table-column>
			<el-table-column
				prop="count"
				label="进线数"
				align="center">
			</el-table-column>
			<el-table-column
				prop="fans_count"
				label="加粉数"
				align="center">
			</el-table-column>
			<el-table-column
				prop="fans_rate"
				label="加粉率"
				align="center">
			</el-table-column>
			<el-table-column
				prop="total_count"
				label="成交单数"
				align="center">
			</el-table-column>
			<el-table-column
				prop="total_amount"
				label="成交总额"
				align="center">
			</el-table-column>
			<el-table-column
				prop="deal_rate"
				label="成交率"
				align="center">
			</el-table-column>
			<el-table-column
				prop="avg_amount"
				label="客单均价"
				align="center">
			</el-table-column>
			<el-table-column
				prop="input_rate"
				label="投产比"
				align="center">
			</el-table-column>
			<el-table-column
				prop="consult_count"
				label="咨询单"
				align="center">
			</el-table-column>
			<el-table-column
				prop="consult_amount"
				label="咨询总额"
				align="center">
			</el-table-column>
			<el-table-column
				prop="append_count"
				label="追访单"
				align="center">
			</el-table-column>
			<el-table-column
				prop="append_amount"
				label="追访总额"
				align="center">
			</el-table-column>
		</el-table>
		<!----------------分页---------------->
		<!--<pagination :total="total" :page.sync="listQuery.page" :limit.sync="listQuery.limit"
								@pagination="getList"/>-->
		</div>
		
	</div>
</template>

<script>
import Pagination from '@/components/Pagination'
import {parseTime} from '@/utils'
import {getTodayBranchingReport} from '@/api/ggkq';
import {mapGetters, mapState} from 'vuex';

export default {
  name: "todayBranchingReport",
  components: {
    // Pagination,
  },
  watch:{	// 监视数据改变,重新绘制Chart
    barData: {
      handler(newValue, oldValue) {
        console.log(newValue)
        this.barDataX = []
        this.barDataTotalY = []
        if (Object.keys(newValue).length) {
          newValue.forEach(item => {
            this.barDataX.push(item.staff_name)
            this.barDataTotalY.push(item.count)
          })
          // newValue.emr.forEach(item => {
          //   this.barDataEmrY.push(item.count)
          // })
        }
        this.$nextTick(() => {
          this.chartInstanceBar.dispose()
          this._initChartBar()
        })
      },
      immediate: true,
      deep: true
    },

    lineData: {
      handler(newValue, oldValue) {
        console.log(newValue)
        this.lineDataX = []
        this.lineDataTotalY = []
        if (Object.keys(newValue).length) {
          newValue.forEach(item => {
            this.lineDataX.push(item.staff_name)
            this.lineDataTotalY.push(item.count)
          })
          // newValue.emr.forEach(item => {
          //   this.barDataEmrY.push(item.count)
          // })
        }
        this.$nextTick(() => {
          this.chartInstanceLine.dispose()
          this._initChartLine()
        })
      },
      immediate: true,
      deep: true
    },

    PieDataOrigin: {
      handler(newValue, oldValue) {
        console.log(newValue)
        this.PieData = []
        if (Object.keys(newValue).length) {
          newValue.forEach(item => {
            var temp = {
              value : item.count,
              name: item.staff_name
            }
            this.PieData.push(temp)
          })
          // newValue.emr.forEach(item => {
          //   this.barDataEmrY.push(item.count)
          // })
        }
        this.$nextTick(() => {
          this.chartInstancePie.dispose()
          this._initChartPie()
        })
      },
      immediate: true,
      deep: true
    },

  },
  mounted() {
    this._initChartBar()	//初始化Chart实例
    this._initChartLine()
    this._initChartPie()
    window.addEventListener("resize", this.chartInstanceBar.resize);	// 监听页面大小改变
    window.addEventListener("resize", this.chartInstanceLine.resize);	// 监听页面大小改变
    window.addEventListener("resize", this.chartInstancePie.resize);	// 监听页面大小改变
  },
  created () {
    console.log(this.$route.path)
    console.log(this.operates)
    this.operates.forEach(operate => {
      if(operate[this.$route.path]) {
        console.log(operate[this.$route.path].split(','))
        this.operateList.push(...operate[this.$route.path].split(','))
      }
    })
    this.getList()
  },
  computed: {
    ...mapGetters(["agentId", "adminId"]),
    ...mapState({
      is_admin: state => state.user.is_admin,
      operates: state => state.user.operates,
    }),
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.chartInstanceBar)	// 页面关闭时候移除监听
    window.removeEventListener('resize', this.chartInstanceLine)	// 页面关闭时候移除监听
    window.removeEventListener('resize', this.chartInstancePie)	// 页面关闭时候移除监听
  },
  data () {
    return {
      choiceDate: [],
      searchParams: {
        created_at: [],
      },
      listQuery: {
        page: 1,
        limit: 100,
        importance: undefined,
        title: undefined,
        type: undefined,
        sort: '+id',
      },
      total: 0,
      listLoading: false,
      options: [],
      tableData: [],
      operateList: [],


      isShowTable:false,
      scrollerHeightBar: '484px',
      scrollerHeightLine: '0',
      scrollerHeightPie: '0',

      chartInstanceBar: null,
      barData: [],
      barDataOrigin: [],
      barDataX: [],	// 横轴数据
      barDataTotalY: [],	// 纵轴数据,进线数数
      //  barDataPresY: [12, 23 ,56, 11, 9, 43],

      chartInstanceLine: null,
      lineData: [],
      lineDataOrigin: [],
      lineDataX: [],
      lineDataTotalY: [],


      chartInstancePie: null,
      PieDataOriginOld: [],
      PieDataOrigin: [],
      PieData: [],
    }
  },
  methods: {
    _initChartBar() {
      const initOption = {
        color: ['#2362fb'],
        title: {
          text: ''
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        grid: {
          left: 50,
          right: 30,
          bottom: 40,
        },
        legend: {
          data: ['进线数'],
          itemWidth: 40,
          itemHeight: 20,
        },
        xAxis: {
          type: 'category',
          // data: ['衬衫', '羊毛衫', '雪纺衫', '裤子', '高跟鞋', '袜子']
          data: this.barDataX
        },
        yAxis: {
          type: 'value'
        },
        series: [{
          name: '进线数',
          type: 'bar',
          barWidth: '50%', //柱图宽度
          data:  this.barDataTotalY
        },
          // {
          //   name: '电子病历',
          //   type: 'bar',
          //   barWidth: '20%', //柱图宽度
          //   data: this.barDataEmrY
          // }
        ]
      }
      this.chartInstanceBar = this.$echarts.init(this.$refs.recordReportBar)	// 通过DOM获取Echart实例
      this.chartInstanceBar.showLoading()
      this.chartInstanceBar.setOption(initOption)	// 初始化数据
      this.chartInstanceBar.hideLoading();
    },

    _initChartLine() {
      const initOption = {
        color: ['#2362fb'],
        title: {
          text: ''
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        grid: {
          left: 50,
          right: 30,
          bottom: 40,
        },
        legend: {
          data: ['进线数'],
          itemWidth: 40,
          itemHeight: 20,
        },
        xAxis: {
          type: 'category',
          data: this.barDataX
        },
        yAxis: {
          type: 'value'
        },
        series: [{
          name: '进线数',
          type: 'line',
          data:  this.barDataTotalY
        },
          // {
          //   name: '电子病历',
          //   type: 'bar',
          //   barWidth: '20%', //柱图宽度
          //   data: this.barDataEmrY
          // }
        ]
      }
      this.chartInstanceLine = this.$echarts.init(this.$refs.recordReportLine)	// 通过DOM获取Echart实例
      this.chartInstanceLine.showLoading()
      this.chartInstanceLine.setOption(initOption)	// 初始化数据
      this.chartInstanceLine.hideLoading();
    },

    _initChartPie() {
      const initOption = {
        color: ['#5470c6', '#91cc75', '#fac858','#ee6666', '#73c0de', '#3ba272','#fc8452', '#9a60b4', '#ea7ccc'],
        title: {
          text: ''
        },
        tooltip: {
          trigger: 'item'
        },

        grid: {
          left: 50,
          right: 30,
          bottom: 40,
        },
        legend: {
          orient: 'horizontal',
          left: 'center'
        },
        series: [{
          type: 'pie',
          radius: '75%',
          data:  this.PieData
        },],
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: 'rgba(0, 0, 0, 0.5)'
          }
        }
      }
      this.chartInstancePie = this.$echarts.init(this.$refs.recordReportPie)	// 通过DOM获取Echart实例
      this.chartInstancePie.showLoading()
      this.chartInstancePie.setOption(initOption)	// 初始化数据
      this.chartInstancePie.hideLoading();
    },
		
		
    getList (type) {
      this.tableData =[]
      if (type == 'restPage') {
        this.listQuery.page = 1
      }
      this.listLoading = true
      // this.searchParams.page = this.listQuery.page
      // this.searchParams.page_size = this.listQuery.limit
      getTodayBranchingReport(this.searchParams).then(response => {
        let data = response
        if (data.code == 200) {
          let _data = data.data
          this.barData = data.data.list
          this.lineData = data.data.list
          this.PieDataOrigin = data.data.list
          // this.total = _data.total
          // this.listQuery.limit = _data.per_page


          var flag = 12;
          var keys = ['count', 'fans_count', 'fans_rate', 'total_count', 'total_amount', 'deal_rate', 'avg_amount', 'input_rate',
					'consult_count', 'consult_amount', 'append_count', 'append_amount'];

          _data.list.forEach(item => {
            var count = 0;

            keys.forEach(key => {
              if(item[key] == 0) {
                count++
              }
            })

            if(count != flag){
              this.tableData.unshift(item)
            }
          })
					
					
					
          // this.tableData = _data.list
          _data.total.staff_name = "汇总"
         	this.tableData.unshift(_data.total)
        }
        this.listLoading = false
      }).catch(error => {
        console.log(error);
        this.listLoading = false
      })
    },
    handleChangeDate() {
      this.searchParams.start_at = []
      this.searchParams.end_at = []
      this.searchParams.created_at = ""
      console.log(this.choiceDate)
      var _tmp0
      var _tmp1
      if (this.choiceDate) {
        _tmp0 = this.choiceDate[0].substring(0,10)
        _tmp1 = this.choiceDate[1].substring(0,10)
        this.searchParams.created_at += _tmp0 + ","
        this.searchParams.created_at += _tmp1
      } else {

        this.searchParams.created_at = ""
      }
      this.getList('restPage')
    },
    handlerExportExcel () {
      this.downloadLoading = true
      import('@/vendor/Export2Excel').then(excel => {
        const tHeader = ['客服', '进线数', '加粉数', '加粉率', '成交单数', '成交总额',
          '成交率', '客单均价', '投产比', '咨询单', '咨询总额', '追访单',
				'追访总额'] // table表的 label
        const filterVal = ['staff_name', 'count', 'fans_count', 'fans_rate', 'total_count', 'total_amount',
          'deal_rate', 'avg_amount', 'input_rate', 'consult_count', 'consult_amount', 'append_count',
				'append_amount'] // table表 prop
        const list = this.tableData
        const data = this.formatJson(filterVal, list)
        excel.export_json_to_excel({
          header: tHeader,
          data,
          filename: '进线日报',
          autoWidth: true,
          bookType: 'xlsx'
        })
        this.downloadLoading = false
      })
    },
    formatJson(filterVal, jsonData) {
      return jsonData.map(v => filterVal.map(j => {
        if (j === 'timestamp') {
          return parseTime(v[j])
        } else {
          return v[j]
        }
      }))
    },


    handleSelectionChange(val) {
      console.log(val, "val");
      //去判断按钮的显示与隐藏
      this.multipleSelection = val;
      if(val.length == 0) {
        this.getList()
      } else {
        this.barData = val;
        this.lineData = val;
        this.PieDataOrigin = val;
      }

    },
    handlerBar() {
      this.scrollerHeightBar = '484px'
      this.scrollerHeightLine = '0px'
      this.scrollerHeightPie = '0px'
      this.$nextTick(() => {
        this.chartInstanceBar.dispose()
        this._initChartBar()
        this.chartInstanceLine.dispose()
        this._initChartLine()
        this.chartInstancePie.dispose()
        this._initChartPie()
      })
    },
    handlerLine() {
      this.scrollerHeightBar = '0px'
      this.scrollerHeightLine = '484px'
      this.scrollerHeightPie = '0px'
      this.$nextTick(() => {
        this.chartInstanceBar.dispose()
        this._initChartBar()
        this.chartInstanceLine.dispose()
        this._initChartLine()
        this.chartInstancePie.dispose()
        this._initChartPie()
      })
    },
    handlerPie() {
      this.scrollerHeightBar = '0px'
      this.scrollerHeightLine = '0px'
      this.scrollerHeightPie = '484px'
      this.$nextTick(() => {
        this.chartInstanceBar.dispose()
        this._initChartBar()
        this.chartInstanceLine.dispose()
        this._initChartLine()
        this.chartInstancePie.dispose()
        this._initChartPie()
      })
    },
    handlerHideTable() {
      this.isShowTable = !this.isShowTable;
    },
  }
}
</script>

<style lang="scss" scoped>
	/* switch按钮样式 */
	::v-deep.switch {
		.el-switch__label {
			position: absolute;
			display: none;
			color: #fff !important;
		}
		
		/*打开时文字位置设置*/
		.el-switch__label--right {
			z-index: 1;
		}
		
		/* 调整打开时文字的显示位子 */
		.el-switch__label--right span {
			margin-right: 11px;
		}
		
		/*关闭时文字位置设置*/
		.el-switch__label--left {
			z-index: 1;
		}
		
		/* 调整关闭时文字的显示位子 */
		.el-switch__label--left span {
			margin-left: 11px;
		}
		
		/*显示文字*/
		.el-switch__label.is-active {
			display: block;
		}
		
		/* 调整按钮的宽度 */
		.el-switch__core, .el-switch__label {
			width: 55px !important;
			margin: 0;
		}
	}
</style>
